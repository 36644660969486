<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem.customer ? `${dataItem.customer.name}` : "" }}
  </td>
  <td v-else>
    <!-- <dropdownlist
      :data-items="customers"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
    >
    </dropdownlist> -->
    <combobox
        :data-items="customers"
        :text-field="'name'"
        :data-item-key="'id'"
        :value="value"
        @change="change"
        :allow-custom="true"
    ></combobox>
    <!-- <combobox
      :data-items="customers"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
      :allow-custom="true"
    /> -->
  </td>
</template>
<script>
import Service from "@/services/Service.js";

import { ComboBox } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    combobox: ComboBox
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      customers: [],
      value: {},
      defaultItem: {
        name: "Select customer ...",
        id: 0,
      },
    };
  },
  created: function () {
    // lookup up customer
    console.log(`dataItem: ${this.dataItem}, field: ${this.field}`);
    if(this.dataItem.customer) {
      this.value = {id: this.dataItem.customer.id, name: this.dataItem.customer.name}
    }
    // console.log("ContactDropDownCell created...");
    this.getData();
  },
  methods: {
    getData: function () {
      Service.getCustomers()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.customers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      // handle new value
      if(e.target.value.id == undefined) {
        // TODO: determine if we want this functionality here

        // Service.createCustomer({name: e.target.value.name})
        // .then((response) => {
        //   // console.log("getData call... set gridData");
        //   const customer = response.data;
        //   this.value = {id: customer.id, name: customer.name};
        //   e.target.value.id = customer.id;
        //   this.$emit("change", e, customer);
        // })
        // .catch((error) => {
        //   console.log(error);
        // });
      } else {
        this.value = {id: e.target.value.id, name: e.target.value.name};
        this.$emit("change", e, e.target.value);
      }
    },
  },
};
</script>