<template>
    <v-dialog v-model="show" width="500">
      <v-card>
       <button class="v-btn white" id="x_btn" @click="closeNewUser">
        &#10006;
       </button>
       <v-layout align-center justify-center>
           <v-flex class="white">
                <v-layout align-center justify-center class="mt-4">
                   <h4>Add New User</h4>
                </v-layout>
                <v-form
                ref="form"
                id="account_form"   
                lazy-validation
                >
                    <v-alert
                        :value="showError"
                        type="error"
                    >
                        {{ errorMessage }}
                    </v-alert>

                    <v-text-field
                        v-model="name"
                        label="Name"
                        required
                        :rules="[(v) => !!v || 'Name is required']"
                    >
                    </v-text-field>
                    <v-select
                      v-model="userType"
                      :items="usertypes"
                      label="Select User Type"
                      :item-text="'name'"
                      :item-value="'id'"
                      required
                      :rules="[(v) => !!v || 'Customer is required']"
                    >
                    </v-select>
                    <v-select
                        :items="customers"
                        required
                        :rules="[(v) => !!v || 'Customer is required']"
                        label="Select Company"
                        :item-text="'name'"
                        :item-value="'id'"
                        v-model="customerId"
                        >
                    </v-select>
                    <v-text-field
                        v-model="email"
                        label="E-mail"
                        required
                        :rules="emailRules"
                    >
                    </v-text-field>
                    <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="passwordRules"
                        :type="show1 ? 'text' : 'password'"
                        label="Password"
                        required
                        @click:append="show1 = !show1"
                    >
                    </v-text-field>
                    <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success"
                        block
                        @click="submitForm"
                    >
                        Create Account
                    </v-btn>

                </v-form>
                <v-layout align-center justify-center class="mt-4">
                    <p class="caption">
                        Already a member? 
                        <router-link :to="{ name: 'login' }">
                            Login
                        </router-link>
                    </p>
                </v-layout>
           </v-flex>
       </v-layout>
      </v-card>
    </v-dialog>
</template>
<script>
import Service from '@/services/Service.js';

import Vue from "vue";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

Vue.use(VueToast)
export default {
  components: {
    dropdownlist: DropDownList,
  },
  props: {
    value: Boolean,
    dataItem: Object,
    field: String
  },
  data() {
    return {
      showError: false,
      show1: false,
      customers: [],
      customerId: null,
      usertypes: [],
      userType: null,
      gridData: [],
      defaultItem: {
        name: "Select customer ...",
        id: 0,
      },
      errorMessage: "",
      password: '',
      name: '',
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      errorMessage: "",
      disabled: true,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  computed: {
    show: {
        get () {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
  created: function () {
    this.getCustomers();
    this.getData();
    },
  methods: {
    closeNewUser() {
      this.show = false;
    },
    getData: function () {
      Service.getUserTypes()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.usertypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCustomers() {
      Service.getCustomers()
        .then((response) => {
          this.customers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitForm () {
      
      let valid = this.$refs.form.validate();
      let user_data = {
        user: {
          email: this.email,
          name: this.name,
          password: this.password,
          customer_id: this.customerId,
          user_type: this.userType
        }
      };
      if(valid) {
        
        Service.register(user_data)
        .then((response) => {
          // message
          console.log(response.data)
          this.$emit('my-message', "reload")
        })
        .catch((error) => {
          console.log("Error with user registration:", error.response);
        });
      }
    },
    
    show_submit() {
      let filled = this.email != ""
      if (this.valid && filled) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    validate() {
        if (this.$refs.form.validate()) {
            this.snackbar = true
        }
    },
    register() {
      this.errorMessage = "";
      let data = {
        user: {
          email: this.email,
          name: `${this.firstName} ${this.lastName}`,
          password: this.password
        }
      };
    }
  }
}
</script>
<style>
#account_form {
  margin-left: 6%;
  margin-right: 0%;
}
#x_btn {
  float: right;
  width: 32px;
  height: 32px;
}
</style>