<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem.user_role ? `${dataItem.user_role.name}` : "" }}
  </td>
  <td v-else>
    <!-- <dropdownlist
      :data-items="usertypes"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
    >
    </dropdownlist> -->
    <combobox
        :data-items="usertypesC"
        :text-field="'name'"
        :data-item-key="'id'"
        :value="value"
        @change="change"
        :allow-custom="true"
    ></combobox>
    <!-- <combobox
      :data-items="usertypes"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
      :allow-custom="true"
    /> -->
  </td>
</template>
<script>
import Service from "@/services/Service.js";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
import { ComboBox } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    combobox: ComboBox,
    dropdownlist: DropDownList,
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
    userRole: String,
  },
  data: function () {
    return {
      usertypes: [],
      value: {},
      defaultItem: {
        name: "Select role ...",
        id: 0,
      },
    };
  },
  computed: {
    usertypesC: function() {
      if(this.userRole == "super_user") {
        return this.usertypes
      } else {
        return this.usertypes.slice(1,4)
      }
    }
  },
  created: function () {
    // lookup up user_role
    console.log(`dataItem: ${this.dataItem}, field: ${this.field}`);
    if(this.dataItem.user_role) {
      this.value = {id: this.dataItem.user_role.id, name: this.dataItem.user_role.name}
    }
    // console.log("ContactDropDownCell created...");
    this.getData();
  },
  methods: {
    getData: function () {
      Service.getUserTypes()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.usertypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      // handle new value
      if(e.target.value.id == undefined) {
        // TODO: determine if we want this functionality here

        Service.createCustomer({name: e.target.value.name})
          .then((response) => {
            console.log("getData call... set gridData");
            const user_role = response.data;
            this.value = {id: customer.id, name: customer.name};
            e.target.value.id = customer.id;
            this.$emit("change", e, customer);
        })
         .catch((error) => {
           console.log(error);
        });
      } 
      else {
        this.value = {id: e.target.value.id, name: e.target.value.name};
        this.$emit("change", e, e.target.value);
      }
    },
  },
};
</script>